.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: default;
}

.main {
    width: 100%;
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0 60px 70px 60px;
}

.bg_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.bg_agent {
    width: 93px;
    height: 93px;
    margin-bottom: 20px;
}

.title_llm {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #101417;
    font-size: 20px;
    line-height: 28px;
}

.message_input {
    width: 100%;
    margin-bottom: 20px;
}

.butn_list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 173px;
}

.butn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 132px;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.5s, box-shadow 0.5s;
    user-select: none;
}

.butn_box:not(:last-child) {
    margin-right: 24px;
}

.butn_box:hover {
    background-color: #e4f0f2;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.butn_box:active {
    background-color: #74bdc6;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}

.butn_img {
    max-width: 24px;
    max-height: 24px;
}

.butn_text {
    font-size: 13px;
    color: #000;
}

.current_butn_list {
    width: 100%;
    padding: 0 0 8px 0;
}

.current_butn_box {
    width: 100%;
    height: 43px;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
}

.current_butn_box:not(:last-child) {
    border-bottom: 1px solid #CACFDB;
}

.current_butn_box:hover {
    background-color: #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
    border-radius: 6px;
}

.current_butn_text {
    color: #323a45;
    line-height: 43px;
}