html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* customize */
*,
:after,
:before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* user-select: none; */
    outline: none !important;
    /* font-family: -apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif !important; */
    font-family: 'PingFangSCMedium', sans-serif;
}

a {
    cursor: pointer;
    text-decoration: none;
}

body {
    /* width: 100% !important; */
    overflow: hidden;
    font-family: 'PingFangSCMedium', sans-serif;
}

/* 禁止拖动图片 */
img {
    /* pointer-events: none; */
    -webkit-user-drag: none;
}

body {
    margin: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out;
    transform-origin: top left;
    width: 100vw;
    height: 100vh;
}

#root {
    width: 100%;
    height: 100%;
}

.cite_container {
    position: relative;
    display: inline-block;
}

.cite_icon_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25px;
    padding: 0 5px;
    background-color: #fff;
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    box-sizing: content-box;
}

.cite_icon {
    width: 15px;
    height: 15px;
    font-size: 12px;
}

.cite_tooltip {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

.cite_container:hover .cite_tooltip {
    visibility: visible;
    opacity: 1;
}

.cite_tooltip_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 25px;
    padding: 0 20px 1px 5px;
    border: 1px solid #E7E7E7;
    border-radius: 25px;
    background-color: #fff;
    box-sizing: content-box;
    cursor: pointer;
}

.cite_text {
    font-size: 12px;
    padding: 0 5px 1px;
    white-space: nowrap;
    color: #323A45;
}
