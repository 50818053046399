.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 40px 0 70px 0;
    cursor: default;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 100%;
}

.container_header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 24px;
    color: #101417;
}

.container_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 20px;
}

.container_content_left {
    /* display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; */
    width: 45%;
    height: 100%;
    /* border: 1px solid #D9D9D9; */
    border-radius: 12px;
    padding: 35px 40px;
}

.container_content_right {
    width: 45%;
    height: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    padding: 35px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left_level_box,
.model_box {
    width: 100%;
    height: 500px;
}

.level_middle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: calc(100% - 240px);
}

.level_middle_butn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 38px;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
}

.level_middle_bg_img {
    width: 165px;
    height: 165px;
}

.dollar_img {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 15px;
    color: #323A45;
}

.level_middle_butn_text {

}

.left_butn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    font-family: 'PingFangSCMedium';
    font-size: 16px;
    padding-top: 20px;
}

.haved_add_box {
    padding-bottom: 26px;
    border-bottom: 1px solid #D9D9D9;
}

.no_add_box {
    padding-top: 26px;
}

.content_title {
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 14px;
    color: #101417;
    margin-bottom: 26px;
}

.add_model_box,
.no_add_model_box {
    /* display: flex;
    flex-wrap: wrap;
    gap: 12px; */
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.right_butn_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 70px;
    font-family: 'PingFangSCMedium';
    font-size: 16px;
    padding-top: 19px;
    border-top: 1px solid #D9D9D9;
}

.model_item_box {
    /* flex: 1 0 calc(20% - 10px);
    max-width: calc(20% - 10px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* width: 60px; */
    height: 85px;
}

.model_item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}

.no_add_model_box .model_item {
    cursor: pointer;
    transition: background-color 0.5s, border 0.5s;
    user-select: none;
}

.no_add_model_box .model_item:hover {
    border: 1px solid #17A8B5;
    background-color: #e4f0f2;
}

.no_add_model_box .model_item:active {
    background-color: #a2cfd6;
}

.model_item_img {
    width: 45px;
    height: 45px;
}

.model_item_add_img {
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
    z-index: 10;
}

.model_item_remove {
    /* background-color: #f0f0f0; */
}

.model_item_remove .model_item_grayscale {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(240, 240, 240, 0.5);
    border-radius: 6px;
    z-index: 1;
}

.model_item_name {
    width: 60px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 12px;
    color: #323a45;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.remove_area_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96%;
    border: 1px solid #c8c8c9;
    border-radius: 12px;
    background-color: #ebebec;
}

.remove_area_img {
    width: 78px;
    height: 78px;
    margin-bottom: 30px;
}

.remove_area_text {
    font-family: 'PingFangSCMedium';
    font-size: 16px;
    color: #A6A6A6;
    letter-spacing: 2px;
}

.level_model_item_box {
    position: relative;
    width: 100%;
    height: 120px;
}

.level_model_item {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background-color: #ebebec;
    user-select: none;
}

.level_model_item_img_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.5s, border 0.5s;
}

.level_model_item_add {

}

.level_model_item_add:hover {
    border: 1px solid #17A8B5;
    background-color: #e4f0f2;
}

.level_model_item_add:active {
    background-color: #a2cfd6;
}

.level_model_item_available {
    background-color: transparent;
    transition: background-color 0.5s, border 0.5s;
}

.level_model_item_select {
    border: 1px solid #22A0AC;
}

.level_model_item_top_1 {
    left: 0;
    bottom: 0;
}

.level_model_item_top_2 {
    top: calc(50% - 30px);
    left: calc(25% - 15px);
}

.level_model_item_top_3 {
    top: 0;
    left: calc(50% - 30px)
}

.level_model_item_top_4 {
    top: calc(50% - 30px);
    right: calc(25% - 15px);
}

.level_model_item_top_5 {
    right: 0;
    bottom: 0;
}

.level_model_item_bottom_6 {
    left: 0;
    top: 0;
}

.level_model_item_bottom_7 {
    top: calc(50% - 30px);
    left: calc(25% - 15px);
}

.level_model_item_bottom_8 {
    bottom: 0;
    left: calc(50% - 30px)
}

.level_model_item_bottom_9 {
    top: calc(50% - 30px);
    right: calc(25% - 15px);
}

.level_model_item_bottom_10 {
    right: 0;
    top: 0;
}

.level_model_item_text {
    font-size: 12px;
    color: #B8B8B8;
    line-height: 14px;
}

.container_content_right_unselect {
    /* display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; */
}

.container_content_right_select {
    position: relative;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.container_content_right_select::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.container_content_right_select::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.container_content_right_select::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.right_header_close_butn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 16px;
    height: 16px;
    background: url('../../../../statics/modal_image_close.png') no-repeat center / 100% 100%;
    cursor: pointer;
}

.right_header_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 118px;
    height: 118px;
    margin: 0 auto;
    border-radius: 12px;
    border: 1px solid #e6e6e6;
    margin-top: 25px;
}

.right_header_img {
    width: 100px;
    height: 100px;
}

.right_header_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.right_header_text {
    color: #101417;
    font-family: "PingFangSCMedium";
    font-size: 18px;
    font-weight: 700;
    margin: 12px;
}

.introduce_box,
.example_box {
    width: 100%;
    padding: 10px 0;
}

.introduce_box .attached_components_info_box {
    margin: 10px 0;
}

.attached_components_info_box {
    display: flex;
    width: 100%;
    height: 24px;
    margin-bottom: 3px;
}

.attached_components_icon_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background-color: #fff;
    margin-right: 6px;
}

.attached_components_icon_text {
    color: #4f4f4f;
    font-size: 11px;
    font-weight: bold;
    margin-left: 5px;
}

.introduce_title,
.example_title {
    padding: 0 0 5px 10px;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    font-size: 16px;
    color: #101417;
}

.introduce_text,
.example_text {
    width: 100%;
    padding: 13px;
    border: 1px solid #D9D9D9;
    background-color: #f9f9f9;
    border-radius: 12px;
    font-size: 12px;
    line-height: 16px;
    color: #414648;
}

.example_text img {
    max-width: 100%;
}

.shopping_model_butn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 6px;
    font-size: 11px;
}

.shopping_model_owned {
    width: 83px;
    min-width: 83px;
    color: #74bdc6;
    background-color: #e4f0f2;
    border: 1px solid #74bdc6;
    border-bottom-width: 2px;
}

.shopping_model_purchase {
    width: 128px;
    min-width: 128px;
    color: #EABC4E;
    background-color: #FFF;
    border: 1px solid #D9D9D9;
    border-bottom-width: 2px;
}

.component_container {
    width: 100%;
    height: 100%;
}

.component_model_box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* gap: 10px; */
    row-gap: 35px;
    column-gap: 15px;
}