.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.main {
    width: 100%;
    height: 100%;
}

.header {

}

.title_box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 100px;
}

.title {
    font-size: 38px;
    font-weight: bold;
    color: #262626;
}

.filter_box {
    width: 50%;
    height: 43px;
    margin: 0 auto;
    margin-bottom: 36px;
}

.content_box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 53px - 150px - 43px - 36px);
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.content_box::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
}

.content_box::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.content_box::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.show_box {
    width: calc(50% + 5px);
    margin-right: -10px;
}

.none_box {
    width: 100%;
    height: calc(100% - 100px);
}