.container {
    display: flex;
    margin-top: 32px;
}

.avatar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.img {
    width: 51px;
    height: 51px;
    border-radius: 50%;
}

.ai_img {
    width: 51px;
    height: 51px;
    border-radius: 4px;
}

.content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 63px);
    max-width: calc(100% - 63px);
    /* 63px = 51px + 12px */
}

.content_time {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    color: #999;
}

.content_text {
    position: relative;
    max-width: 100%;
    display: inline-block;
    position: relative;
}

.content_left {
    margin-right: auto;
    background-color: #74bdc6;
    border-radius: 6px;
}

.content_left :global(.markdown-body) {
    color: #fff !important;
    white-space: pre-wrap;
    max-width: 100%;
    padding: 12px;
}

.content_right {
    margin-right: auto;
    background-color: #f9f9f9;
    border-radius: 6px;
}

.content_right :global(.markdown-body) {
    max-width: 100%;
    color: #323A45 !important;
    padding: 12px;
}

.pause_status {
    padding: 12px;
}

.content_recommend {
    margin-bottom: 40px;
}

.content_text_butns {
    position: absolute;
    width: 150px;
    height: 32px;
    right: -200px;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
}

.butn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    border-radius: 50%;
    cursor: pointer;
    background-color: #e4f0f2;
}

.butn:hover {
    background-color: #c6e0e4;
}

.butn_img {
    width: 16px;
    height: 16px;
}

.edit_img {
    background-image: url('../../../../statics/content_text_edit.png');
}

.butn:hover .edit_img {
    background-image: url('../../../../statics/content_text_edit_hover.png');
}

.copy_img {
    background-image: url('../../../../statics/content_text_copy.png');
}

.butn:hover .copy_img {
    background-image: url('../../../../statics/content_text_copy_hover.png');
}

.like_img {
    background-image: url('../../../../statics/content_text_like.png');
}

.butn:hover .like_img {
    background-image: url('../../../../statics/content_text_like_hover.png');
}

.negative_img {
    background-image: url('../../../../statics/content_text_negative.png');
}

.butn:hover .negative_img {
    background-image: url('../../../../statics/content_text_negative_hover.png');
}

.regenerate_img {
    background-image: url('../../../../statics/content_text_regenerate.png');
}

.butn:hover .regenerate_img {
    background-image: url('../../../../statics/content_text_regenerate_hover.png');
}

.butn_active,
.butn_active:hover {
    background-color: #17a8b5;
}

.butn_active .like_img,
.butn_active:hover .like_img {
    background-image: url('../../../../statics/content_text_like_active.png');
}

.butn_active .negative_img,
.butn_active:hover .negative_img {
    background-image: url('../../../../statics/content_text_negative_active.png');
}

.loading_img {
    width: 48px;
    height: 17px;
}

.recommend_box {
    position: absolute;
    right: 0;
    bottom: -40px;
    width: 174px;
    height: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    background-color: #e4f0f2;
    transition: background-color 0.5s ease-in-out;
}

.recommend_box:hover {
    background-image: linear-gradient(to right, #c6e0e4 50%, #e4f0f2 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-color 0.5s ease-in-out, background-position 0.5s ease-in-out;
}

.recommend_box:not(:hover) {
    background-position: right bottom;
}

.recommend_butn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.recommend_butn_text {
    font-size: 14px;
    color: #17a8b5;
    margin-right: 10px;
}

.recommend_butn_icon {
    width: 6px;
    height: 12px;
    background: url('../../../../statics/recommend_butn_icon.png') no-repeat center / 100% 100%;
    transition: transform 0.5s ease;
}

.recommend_box:hover .recommend_butn_icon {
    transform: translateX(5px);
}

.popover_box {
    /* padding: 16px; */
}

.popover_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    height: 40px;
    padding-left: 16px;
    padding-bottom: 3px;
    margin-bottom: 3px;
    cursor: pointer;
    border-radius: 6px;
    transition: background 0.5s;
}

.popover_item:hover {
    background-color: #dfdfdf;
}

.popover_item:active {
    background-color: #cdcdcd;
}

.popover_item_text {
    font-size: 14px;
    color: #605D64;
}

:global(.custom_popover_message .ant-popover-inner) {
    padding: 0;
    border-radius: 6px;
}

.recommend_start,
.recommend_start_active {
    width: 11px;
    height: 10px;
    margin-top: 3px;
    margin-right: 5px;
}

.popover_item_img_box {
    display: flex;
}

.recommend_start {
    background: url('../../../../statics/recommend_star.png') no-repeat center / 100% 100%;
}

.recommend_start_active {
    background: url('../../../../statics/recommend_star_active.png') no-repeat center / 100% 100%;
}

:global(.msg_images_id_box) {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

:global(.msg_images_id_container) {
    width: 200px;
    height: 200px;
    margin-right: 28px;
    /* margin-bottom: 10px; */
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

:global(.msg_images_id_container:last-child) {
    margin-right: 0;
}

:global(.msg_images_id_container_num1),
:global(.msg_images_id_container_num2) {
    width: 342px;
    height: 256px;
}

:global(.msg_images_id_container_num3) {
    width: 220px;
    height: 166px;
}

:global(.msg_images_id) {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    background-color: #f9f9f9 !important;
}

.content_thinking :global(.msg_images_id_container:hover .msg_images_id) {
    transform: none;
}

:global(.msg_images_id_container:hover .msg_images_id) {
    transform: scale(1.1);
}

.content_thinking :global(.msg_images_id_overlay) {
    display: none;
}

:global(.msg_images_id_overlay) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15); /* 灰色半透明 */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

:global(.msg_images_id_container:hover .msg_images_id_overlay) {
    opacity: 1;
}

:global(.msg_images_id_overlay .msg_images_id_like) {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    background: url('../../../../statics/msg_images_id_like.png') no-repeat center / 100% 100%;
    transition: background 0.5s;
}

/* 隐藏伪元素用来预加载悬停背景图 */
:global(.msg_images_id_overlay .msg_images_id_like::after) {
    content: "";
    background: url('../../../../statics/msg_images_id_like_hover.png') no-repeat center / 100% 100%;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
}

:global(.msg_images_id_overlay .msg_images_id_like:hover) {
    background: url('../../../../statics/msg_images_id_like_hover.png') no-repeat center / 100% 100%;
}

:global(.msg_images_id_overlay .msg_images_id_like_active),
:global(.msg_images_id_overlay .msg_images_id_like_active:hover) {
    background: url('../../../../statics/msg_images_id_like_active.png') no-repeat center / 100% 100%;
}

.content_edit {
    width: 100%;
    padding: 16px;
    background-color: #a2cfd6;
}

.user_edit_box {
    position: relative;
    width: 100%;
    background-color: #a2cfd6;
    padding-bottom: 50px;
}

.custom_textarea {
    font-size: 16px;
    color: #fff;
    background-color: #a2cfd6;
    padding: 0;
}

/* 自定义滚动条 */
.custom_textarea::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.custom_textarea::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.custom_textarea::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.custom_textarea:hover,
.custom_textarea:focus,
.custom_textarea:global(.ant-input-outlined),
.custom_textarea:global(.ant-input-outlined:hover),
.custom_textarea:global(.ant-input-outlined:focus) {
    border: none !important;
    background-color: #a2cfd6 !important;
    box-shadow: none !important;
}

.butn_box {
    position: absolute;
    bottom: 0;
    right: 0;
}

.butn_cancel,
.butn_ok {
    width: 75px;
    height: 45px;
    border: 1px solid #17a8b5;
    border-radius: 6px;
    margin-right: 7px;
    font-size: 14px;
    color: #17a8b5;
    background-color: #fff;
    cursor: pointer;
}

.butn_ok {
    background-color: #17a8b5;
    color: #fff;
}

.reasoning_text {
    display: inline-block;
    max-width: 100%;
    padding: 5px 12px 5px;
    margin-bottom: 16px;
    border-left: 1px solid #e5e8eb;
}

/* .reasoning_text p { */
.reasoning_text * {
    color: #abafb7;
    font-size: 16px;
}

.loading_container {
    position: relative;
    max-width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 12px;
}

.loading_box,
.loading_box_done {
    display: inline-flex;
    align-items: center; /* 垂直居中对齐 */
    padding: 12px;
    background-color: #f9f9f9;
    user-select: none;
}

.loading_box_done {
    cursor: pointer;
}

.reasoning_butn,
.reasoning_butn_done,
.reasoning_butn_stop {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 27px;
    color: #abafb7;
    background-color: #f9f9f9;
    border-radius: 6px;
}

.reasoning_butn {
    margin-left: 16px;
}

.reasoning_butn_done {
    color: #323a45;
}

.reasoning_butn_vector_img {
    margin-left: 16px;
}