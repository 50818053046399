.side_arrow_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 22px;
    cursor: pointer;
}

.side_arrow_close {
    width: 4px;
    height: 21px;
}

.side_arrow_open {
    width: 8px;
    height: 22px;
}