.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 20px 0 0;
    font-size: 25px;
    color: #888;
}

.left {
    width: 300px;
    height: 100%;
    padding-left: 18px;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.search_box {
    position: relative;
    width: 200px;
    height: 40px;
    margin-right: 20px;
}

.search {
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px 30px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    font-size: 14px;
}

.search_icon {
    position: absolute;
    top: 15px;
    left: 10px;
    width: 11px;
    height: 12px;
}

.search_box_mask {
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    z-index: -1;
}

.search_info {
    position: absolute;
    left: 0;
    top: 56px;
    z-index: 101;
    width: 214px;
    height: 214px;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* 自定义滚动条 */
.search_info::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.search_info::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: #e9e9e9;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.search_info::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}

.search_info_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 20px;
    font-size: 14px;
    color: #7e8b9e;
    margin-bottom: 16px;
}

.search_info_item {
    margin-bottom: 10px;
}

.search_info_item_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 27px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 6px;
}

.search_info_item_box:hover {
    background-color: #dfdfdf;
}

.search_info_item_box:active {
    background-color: #cdcdcd;
}

.search_info_item_line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #CACFDB;
    margin-bottom: 10px;
}

.search_info_item_icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.search_info_item_name {
    width: calc(100% - 24px - 10px);
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.img_box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}

/* .img_box:hover {
    background-color: #ccc;
} */

.img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.feedback_img {
    background-image: url('../../../../statics/menu_feedback.png');
}

.feedback_img:hover {
    background-image: url('../../../../statics/menu_feedback_active.png');
}

.collect_img {
    background-image: url('../../../../statics/menu_collect.png');
}

.collect_img:hover {
    background-image: url('../../../../statics/menu_collect_active.png');
}

.setting_img {
    background-image: url('../../../../statics/menu_setting.png');
}

.setting_img:hover {
    background-image: url('../../../../statics/menu_setting_active.png');
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.fade_in {
    animation-name: fadeIn;
    /* 动画持续时间为0.5秒 */
    animation-duration: 0.5s;
    /* 动画完成后保持最终状态 */
    animation-fill-mode: forwards;
}

.select_box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.personal_back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;
    font-family: 'PingFangSCMedium';
    font-weight: bold;
    color: #101417;
    font-size: 20px;
    cursor: pointer;
}

.personal_back_img {
    margin-right: 10px;
}

.middle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100%;
    padding-right: 50px;
}

.middle_text {
    font-size: 14px;
}

.middle_text_num {
    font-weight: bold;
    color: #323a45;
}