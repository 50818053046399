.container {
    width: 100%;
    height: 100%;
    background-image: url('../../statics/login_bg.png'); /* 设置背景图片 */
    background-size: cover; /* 覆盖整个 div 区域 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
    background-position: center center; /* 中心对齐背景图片 */
}

.container_loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mobile_container,
.wechat_container,
.qywechat_container {
    position: relative;
    width: 100%;
    height: 100%;
}

.qywechat_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 默认样式（小屏幕时使用图片） */
.wechat_bg_img {
    background: url('../../statics/wechat_container_little.png') no-repeat center / 100% 100%;
}

.wechat_bg_arrow {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 40px;
    right: 40px;
}

/* 大屏幕样式（当屏幕宽度大于 1600px 时使用图片） */
@media (min-width: 1300px) {
    .wechat_bg_img {
        background: url('../../statics/wechat_container.png') no-repeat center / 100% 100%;
    }

    .wechat_bg_arrow {
        width: 150px;
        height: 150px;
        top: 60px;
        right: 60px;
    }
}

.mobile_bg_img {
    background: url('../../statics/mobile_browser.png') no-repeat center / 100% 100%;
}
