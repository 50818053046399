.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.new_chat_box {
    width: 100%;
    height: 40px;
    padding: 0 17px;
}

.progress_bar {
    width: 100%;
    /* height: 40px; */
    padding: 0 17px;
    margin-top: 10px;
}

.chat_type_box {
    width: 100%;
    /* min-height: 45px; */
    /* max-height: 225px; */
    padding: 0 17px;
    margin-top: 10px;
}

.divider_box {
    /* height: 26px; */
    width: 100%;
    padding: 0 17px;
}

.chat_history_box {
    width: 100%;
    /* max-height: calc(100% - 40px - 40px - 10px - 225px - 10px - 26px); */
    /* min-height: calc(100% - 40px - 40px - 10px - 45px - 10px - 26px); */
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 17px;
}

.chat_history_box::-webkit-scrollbar {/*滚动条整体样式*/
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
}

.chat_history_box::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    cursor: pointer;
    border-radius: 5px;
    background: rgba(193, 193, 193, .5);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.chat_history_box::-webkit-scrollbar-thumb:hover {
    background: #e3e3e3;
}
